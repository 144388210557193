import React from 'react';

import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import cx from 'classnames';
import { contentContainer } from '../../scss/content.module.scss';
import { pageNotFound } from './page-not-found.module.scss';

const site = 'sumitpal.in';
const title = 'Page Not Found | 404 | Sumit Pal';
const description = 'You landed here - why? how?';
const seo = {
  title,
  description,
  canonical: site,
  openGraph: {
    url: site,
    title,
    description,
    site_name: site,
  },
};

function PageNotFound() {
  return (
    <Layout seoData={seo}>
      <div className={cx(contentContainer, 'p-t-120', pageNotFound)}>
        <title>Page Not found</title>
        <h1>404</h1>
        <h2>The page you are looking for, isn&#39;t available.</h2>
        <h4 className="p-b-120">
          <Link to="/">Back to Home</Link>
        </h4>
      </div>
    </Layout>
  );
}

export default PageNotFound;
